import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import "./assets/scss/style.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-image-gallery/styles/css/image-gallery.css";
import { Spinner } from 'react-bootstrap';

// Protected route component
import ProtectedRoute from './routes/ProtectedRoute';

// Lazy load page components
const Index = lazy(() => import('./pages'));
const Login = lazy(() => import('./pages/login'));
const Signup = lazy(() => import('./pages/signup'));
const ResetPassword = lazy(() => import('./pages/reset-password'));
const Error = lazy(() => import('./pages/error'));
const ComingSoon = lazy(() => import('./pages/comingsoon'));
const Maintenance = lazy(() => import('./pages/maintenance'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const PostDetail = lazy(() => import('./pages/post-detail'));
const ProductList = lazy(() => import('./pages/product-list'));
const Pots = lazy(() => import('./pages/pots'));


function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Spinner animation="border" variant="primary" /></div>}>
        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/index' element={<Index />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/reset-password' element={<ResetPassword />} />

          <Route path='/product-list' element={<ProductList />} />
          <Route path='/farm-list' element={<ProductList />} />
          <Route path='/community-learning' element={<ProductList />} />

          <Route path='/dashboard' element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path='/dashboard/:tab' element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />

          <Route path='/post/:tab' element={
            <ProtectedRoute>
              <Pots />
            </ProtectedRoute>
          } />
          <Route path='/edit/:tab/:id' element={
            <ProtectedRoute>
              <Pots />
            </ProtectedRoute>
          } />

          <Route path='/detail/:id' element={<PostDetail />} />

          <Route path='*' element={<Error />} />
          <Route path='/error' element={<Error />} />
          <Route path='/comingsoon' element={<ComingSoon />} />
          <Route path='/maintenance' element={<Maintenance />} />
        </Routes>
        <ToastContainer />
      </Suspense>
    </Provider>
  );
}

export default App;
