import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../reducer/userSlice';

const store = configureStore({
  reducer: {
    userSlice: userReducer // add more reducers here if needed
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
