import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  if (!token) return false;
  try {
    const { exp } = jwtDecode(token);
    if (exp < Date.now() / 1000) {
      console.log('token is expired!');
      localStorage.removeItem('token');
      return false;
    }
    return true;
  } catch (e) {
    console.log(e)
    localStorage.removeItem('token');
    return false;
  }
};

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default ProtectedRoute;
